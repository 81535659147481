var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { Field } from 'rc-field-form';
import omit from 'omit.js';
import Row from '../grid/row';
import { ConfigContext } from '../config-provider';
import { tuple } from '../_util/type';
import warning from '../_util/warning';
import FormItemLabel from './FormItemLabel';
import FormItemInput from './FormItemInput';
import { FormContext, FormItemContext } from './context';
import { toArray, getFieldId } from './util';
const ValidateStatuses = tuple('success', 'warning', 'error', 'validating', '');
const MemoInput = React.memo(({ children }) => {
    return children;
}, (prev, next) => {
    return prev.value === next.value && prev.update === next.update;
});
function hasValidName(name) {
    if (name === null) {
        warning(false, 'Form.Item', '`null` is passed as `name` property');
    }
    return !(name === undefined || name === null);
}
function FormItem(props) {
    const { name, fieldKey, noStyle, dependencies, prefixCls: customizePrefixCls, style, className, shouldUpdate, hasFeedback, help, rules, validateStatus, children, required, trigger = 'onChange', validateTrigger = 'onChange' } = props, restProps = __rest(props, ["name", "fieldKey", "noStyle", "dependencies", "prefixCls", "style", "className", "shouldUpdate", "hasFeedback", "help", "rules", "validateStatus", "children", "required", "trigger", "validateTrigger"]);
    const destroyRef = React.useRef(false);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const formContext = React.useContext(FormContext);
    const { updateItemErrors } = React.useContext(FormItemContext);
    const [domErrorVisible, innerSetDomErrorVisible] = React.useState(!!help);
    const [inlineErrors, innerSetInlineErrors] = React.useState({});
    function setDomErrorVisible(visible) {
        if (!destroyRef.current) {
            innerSetDomErrorVisible(visible);
        }
    }
    function setInlineErrors(errors) {
        if (!destroyRef.current) {
            innerSetInlineErrors(errors);
        }
    }
    const { name: formName } = formContext;
    const hasName = hasValidName(name);
    // Cache Field NamePath
    const nameRef = React.useRef([]);
    // Should clean up if Field removed
    React.useEffect(() => {
        return () => {
            destroyRef.current = true;
            updateItemErrors(nameRef.current.join('__SPLIT__'), []);
        };
    }, []);
    const prefixCls = getPrefixCls('form', customizePrefixCls);
    // ======================== Errors ========================
    // Collect noStyle Field error to the top FormItem
    const updateChildItemErrors = noStyle
        ? updateItemErrors
        : (subName, subErrors) => {
            if (!isEqual(inlineErrors[subName], subErrors)) {
                Promise.resolve().then(() => {
                    setInlineErrors(Object.assign(Object.assign({}, inlineErrors), { [subName]: subErrors }));
                });
            }
        };
    function renderLayout(baseChildren, fieldId, meta, isRequired) {
        if (noStyle) {
            return baseChildren;
        }
        // ======================== Errors ========================
        let mergedErrors;
        if (help !== undefined && help !== null) {
            mergedErrors = toArray(help);
        }
        else {
            mergedErrors = meta ? meta.errors : [];
            Object.keys(inlineErrors).forEach(subName => {
                const subErrors = inlineErrors[subName] || [];
                if (subErrors.length) {
                    mergedErrors = [...mergedErrors, ...subErrors];
                }
            });
        }
        // ======================== Status ========================
        let mergedValidateStatus = '';
        if (validateStatus !== undefined) {
            mergedValidateStatus = validateStatus;
        }
        else if (meta && meta.validating) {
            mergedValidateStatus = 'validating';
        }
        else if (!help && mergedErrors.length) {
            mergedValidateStatus = 'error';
        }
        else if (meta && meta.touched) {
            mergedValidateStatus = 'success';
        }
        const itemClassName = {
            [`${prefixCls}-item`]: true,
            [`${prefixCls}-item-with-help`]: domErrorVisible || help,
            [`${className}`]: !!className,
            // Status
            [`${prefixCls}-item-has-feedback`]: mergedValidateStatus && hasFeedback,
            [`${prefixCls}-item-has-success`]: mergedValidateStatus === 'success',
            [`${prefixCls}-item-has-warning`]: mergedValidateStatus === 'warning',
            [`${prefixCls}-item-has-error`]: mergedValidateStatus === 'error',
            [`${prefixCls}-item-has-error-leave`]: !help && domErrorVisible && mergedValidateStatus !== 'error',
            [`${prefixCls}-item-is-validating`]: mergedValidateStatus === 'validating',
        };
        // ======================= Children =======================
        return (<Row className={classNames(itemClassName)} style={style} key="row" {...omit(restProps, [
            'colon',
            'extra',
            'getValueFromEvent',
            'hasFeedback',
            'help',
            'htmlFor',
            'id',
            'label',
            'labelAlign',
            'labelCol',
            'normalize',
            'required',
            'validateFirst',
            'validateStatus',
            'valuePropName',
            'wrapperCol',
        ])}>
        
        <FormItemLabel htmlFor={fieldId} required={isRequired} {...props} prefixCls={prefixCls}/>
        
        <FormItemInput {...props} {...meta} errors={mergedErrors} prefixCls={prefixCls} onDomErrorVisibleChange={setDomErrorVisible} validateStatus={mergedValidateStatus}>
          <FormItemContext.Provider value={{ updateItemErrors: updateChildItemErrors }}>
            {baseChildren}
          </FormItemContext.Provider>
        </FormItemInput>
      </Row>);
    }
    const isRenderProps = typeof children === 'function';
    if (!hasName && !isRenderProps && !dependencies) {
        return renderLayout(children);
    }
    // Record for real component render
    const updateRef = React.useRef(0);
    updateRef.current += 1;
    return (<Field {...props} trigger={trigger} validateTrigger={validateTrigger} onReset={() => {
        setDomErrorVisible(false);
    }}>
      {(control, meta, context) => {
        const { errors } = meta;
        const mergedName = toArray(name).length && meta ? meta.name : [];
        const fieldId = getFieldId(mergedName, formName);
        if (noStyle) {
            nameRef.current = [...mergedName];
            if (fieldKey) {
                nameRef.current[nameRef.current.length - 1] = fieldKey;
            }
            updateItemErrors(nameRef.current.join('__SPLIT__'), errors);
        }
        const isRequired = required !== undefined
            ? required
            : !!(rules &&
                rules.some(rule => {
                    if (rule && typeof rule === 'object' && rule.required) {
                        return true;
                    }
                    if (typeof rule === 'function') {
                        const ruleEntity = rule(context);
                        return ruleEntity && ruleEntity.required;
                    }
                    return false;
                }));
        // ======================= Children =======================
        const mergedControl = Object.assign(Object.assign({}, control), { id: fieldId });
        let childNode = null;
        if (Array.isArray(children) && hasName) {
            warning(false, 'Form.Item', '`children` is array of render props cannot have `name`.');
            childNode = children;
        }
        else if (isRenderProps && (!shouldUpdate || hasName)) {
            warning(!!shouldUpdate, 'Form.Item', '`children` of render props only work with `shouldUpdate`.');
            warning(!hasName, 'Form.Item', "Do not use `name` with `children` of render props since it's not a field.");
        }
        else if (dependencies && !isRenderProps && !hasName) {
            warning(false, 'Form.Item', 'Must set `name` or use render props when `dependencies` is set.');
        }
        else if (React.isValidElement(children)) {
            const childProps = Object.assign(Object.assign({}, children.props), mergedControl);
            // We should keep user origin event handler
            const triggers = new Set([...toArray(trigger), ...toArray(validateTrigger)]);
            triggers.forEach(eventName => {
                childProps[eventName] = (...args) => {
                    var _a, _b, _c;
                    (_a = mergedControl[eventName]) === null || _a === void 0 ? void 0 : _a.call(mergedControl, ...args);
                    (_c = (_b = children.props)[eventName]) === null || _c === void 0 ? void 0 : _c.call(_b, ...args);
                };
            });
            childNode = (<MemoInput value={mergedControl[props.valuePropName || 'value']} update={updateRef.current}>
              {React.cloneElement(children, childProps)}
            </MemoInput>);
        }
        else if (isRenderProps && shouldUpdate && !hasName) {
            childNode = children(context);
        }
        else {
            warning(!mergedName.length, 'Form.Item', '`name` is only used for validate React element. If you are using Form.Item as layout display, please remove `name` instead.');
            childNode = children;
        }
        return renderLayout(childNode, fieldId, meta, isRequired);
    }}
    </Field>);
}
export default FormItem;
